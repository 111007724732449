import dayjs from 'dayjs'
import useSWR from 'swr-0-5-6'
import { Socket } from '~/modules/SDK/socket2/Socket'
import { apirc } from '~/configs/apirc'
import { FrontEndSortKey } from '~/modules/ranking-realtime/useRealtimeRankingState'
import urlcat from 'urlcat'
import { useLatestTradeDateStore } from '~/modules/screener/containers/useLatestTradeDateStore'
import type { staticStore } from '~/pages/heineken_template/_private/staticStore'
import { useAsync } from 'react-use'
import axios from 'axios'

const ROOT_URL = apirc.stock.stockScreenerURL
export type TurnoverRateResourceData = {
  /** E.g. 2020-10-10 */
  date: string
  symbol: Socket.SymbolString
  open: number
  high: number
  low: number
  close: number
  volume: number
  avg_turnover_rate: number
  turnover_rate: number
}

export const ActionEnum = {
  buy: '買超',
  sell: '賣超',
}

export const WarrantTypeEnum = {
  call: '認購',
  put: '認售',
}

export const PowerEnum = {
  strong: '強勢',
  weak: '弱勢',
}

export const InstitutionEnum = {
  foreigner: '外資',
  trust: '投信',
  dealer: '自營商',
  dealer_hedge: '自營商(避險)',
  all: '全部',
}

export const CrossTypeEnum = {
  golden: '黃金',
  death: '死亡',
}

export const JoinEnum = {
  union: '聯集',
  intersection: '交集',
}

export const IndicatorEnum = {
  RGR_MODE_A: '紅黑紅-A式',
  RGR_MODE_B: '紅黑紅-B式',
  GRG: '黑紅黑',
  BIRD_MOUTH: '鳥嘴',
  REVERSE_BIRD_MOUTH: '反鳥嘴',
}

export const DailyRankEnum = {
  change_percent: '指定區間漲跌幅累積',
}

export type AvailableIndicator = keyof typeof IndicatorEnum
export type InstitutionKey = keyof typeof InstitutionEnum
export type ActionKey = keyof typeof ActionEnum
export type PowerKey = keyof typeof PowerEnum
export type CrossTypeKey = keyof typeof CrossTypeEnum
export type DailyRankKey = keyof typeof DailyRankEnum
export type WarrantTypeKey = keyof typeof WarrantTypeEnum
export type PercentageStatusTypeKey = keyof typeof PercentRangeStatusEnum

export const percentRangeStatusSeparator = ' - '
export const PercentRangeStatusEnum = {
  new_high: `創新高${percentRangeStatusSeparator}當日高點創days區間的新高點 (>100%)`,
  new_low: `創新低${percentRangeStatusSeparator}當日高點創days區間中的新低點 (<0)`,
  near_high: `接近區間高點${percentRangeStatusSeparator}當日高點接近區間高點 (90%~100%)`,
  near_low: `接近區間低點${percentRangeStatusSeparator}當日低點接近區間低點 (0%~10%)`,
  both_high_low: `創高破底${percentRangeStatusSeparator}曾經碰過歷史高、低點`,
  normal: `區間震盪${percentRangeStatusSeparator}無`,
}

export type LatestTradeDateSizeResource = {
  date: string
}

export type BigThreeConsecutiveResourceData = {
  symbol: Socket.SymbolString
  volume: number
  diff_volume: number
  diff_volume_percentage: number
}

export type ZScoreResourceData = {
  /** E.g. 2020-10-10 */
  date: string
  symbol: Socket.SymbolString
  open: number
  high: number
  low: number
  close: number
  volume: number
  z_score: number
}

export type PopularResourceData = {
  /** E.g. 2020-10-10 */
  date: string
  symbol: Socket.SymbolString
  value: number
  volume: number
}

export type KdCrossResourceData = {
  /** E.g. 2020-10-10 */
  date: string
  symbols: Socket.SymbolString[]
}

export type ExtremeVolumeResourceData = {
  /** E.g. 2020-10-10 */
  date: string
  symbol: Socket.SymbolString
  open: number
  high: number
  low: number
  close: number
  volume: number
  volume_ma: number
}

export type OrderBy = 'desc' | 'asc'

export type DailyRankResourceData = {
  /** E.g. 2020-10-10 */
  date: string
  datetime: string
  symbol: Socket.SymbolString
  open: number
  high: number
  low: number
  close: number
  volume: number
  last_close: number
  last_volume: number
  change: number
  change_percent: number
  amplitude: number
  amount: number
}

export type WarrantBSResourceData = {
  date: string
  code: string
  name: string
  type: string
  bs_volume: number
  bs_value: number
}

export type AdvanceDeclineResourceData = {
  /** E.g. 2020-10-10 */
  name: string
  count: { [key in number]: number }
  percentage: { [key in number]: number }
}

export type PercentRangeResourceData = {
  date: string
  symbol: Socket.SymbolString
  open: number
  high: number
  low: number
  close: number
  volume: number
  amount: number
  prev_high_date: string
  prev_low_date: string
  prev_high: number
  prev_low: number
  percentage: number
  status: PercentageStatusTypeKey
}

export type ContinuousColorData = {
  symbol: string
  change_percent: number
}

export type StockAboveMA = {
  name: string
  count: { 0: string; 1: string }
  percentage: { 0: string; 1: string }
}

type DefaultOption = {
  volume_gte: number
  volume_lte: number
  amount_gte: number
  amount_lte: number
}

/**
 * # 計畫以 {@link staticStore} 替代
 *
 * 可取得的最近交易日統計資訊YYYY-MM-DD
 *
 * @deprecated
 */
export const useLatestTradeDate = () => {
  const res = useSWR<{ date: string }>(`${ROOT_URL}/latest_trade_date`, errorEmptyArrayFetcher, {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
  })
  const date = res.data?.date
  if (date) useLatestTradeDateStore.setState({ date })
}

/**
 * # 計畫以 {@link staticStore} 替代
 *
 * 可取得盤中交易 統計資訊YYYY-MM-DD
 *
 * @deprecated
 */
export const useIntraday = () => {
  const res = useSWR<{ date: string }>(
    `${ROOT_URL}/latest_trade_date?intraday=true`,
    errorEmptyArrayFetcher,
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    },
  )
  const intraday = res.data?.date
  if (intraday) useLatestTradeDateStore.setState({ intraday })

  return intraday
}

/** 可取得的最近N日交易日統計資訊['YYYY-MM-DD',...] */
export const useLatestTradeDateSize = (options?: { size: number }) => {
  const size = options?.size ?? 5
  const url = urlcat(`${ROOT_URL}/latest_trade_date`, { size })
  const res = useSWR<LatestTradeDateSizeResource[]>(url, errorEmptyArrayFetcher, {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
  })
  const date = res.data?.map(s => s.date)

  return date
}

/** 換手率 */
export const useTurnoverRateResource = (
  options: DefaultOption & {
    date: string
    days: number
    threshold: number
  },
) => {
  const { volume_gte, volume_lte, amount_gte, amount_lte } = options
  const date = options?.date ?? dayjs().format('YYYY-MM-DD')
  const threshold = options?.threshold ?? 0.1
  const days = options?.days ?? 5
  const url = urlcat(`${ROOT_URL}/turnover_rate`, {
    date,
    days,
    threshold,
    volume_gte,
    volume_lte,
    amount_gte,
    amount_lte,
  })
  const res = useSWR<TurnoverRateResourceData[]>(url, errorEmptyArrayFetcher, {
    shouldRetryOnError: false,
  })

  return {
    data: res.data?.sort((a, b) => b.turnover_rate - a.turnover_rate),
  }
}

/** 三大連續天數 買、賣 */
export const useBig3ConsecutiveResource = (
  options: Partial<DefaultOption> & {
    date: string
    days: number
    action: ActionKey
    institution: InstitutionKey
  },
) => {
  const { volume_gte, volume_lte, amount_gte, amount_lte } = options
  const { date, days, action, institution } = options
  const url = urlcat(`${ROOT_URL}/big3_consecutive`, {
    date,
    days,
    action,
    institution,
    volume_gte,
    volume_lte,
    amount_gte,
    amount_lte,
  })
  const res = useSWR<BigThreeConsecutiveResourceData[]>(url, errorEmptyArrayFetcher, {
    shouldRetryOnError: false,
  })

  return {
    data: res.data,
  }
}

/** 爆量 */
export const useExtremeVolumeResource = (
  options: Partial<DefaultOption> & { date: string; days: number; threshold?: number },
) => {
  const { volume_gte, volume_lte, amount_gte, amount_lte, threshold } = options
  const date = options?.date ?? dayjs().format('YYYY-MM-DD')
  const days = options?.days ?? 5
  const url = urlcat(`${ROOT_URL}/extreme_volume`, {
    date,
    days,
    threshold,
    volume_gte,
    volume_lte,
    amount_gte,
    amount_lte,
  })
  const res = useSWR<ExtremeVolumeResourceData[]>(url, errorEmptyArrayFetcher, {
    shouldRetryOnError: false,
  })

  return {
    data: res.data,
  }
}

/** 強弱股票 */
export const useZScoreResource = (
  options: Partial<DefaultOption> & { date: string; days: number; type: PowerKey; limit?: number },
) => {
  const { volume_gte, volume_lte, amount_gte, amount_lte } = options
  const { date, days, type, limit } = options
  const itemLimit = limit ?? 200
  const url = urlcat(`${ROOT_URL}/z_score`, {
    date,
    days,
    type,
    volume_gte,
    volume_lte,
    amount_gte,
    amount_lte,
    limit: itemLimit,
  })
  const res = useSWR<ZScoreResourceData[]>(url, errorEmptyArrayFetcher, {
    shouldRetryOnError: false,
  })

  return {
    data: res.data,
  }
}

/** 即時排行 */
export const useDailyRankResource = (
  options: DefaultOption & {
    date?: string
    limit?: number
    type?: DailyRankKey
    days?: number
    threshold?: number
    order_by?: OrderBy
    sort_by?: FrontEndSortKey
  },
  flag: number,
) => {
  const {
    date,
    volume_gte,
    volume_lte,
    amount_gte,
    amount_lte,
    order_by,
    sort_by,
    days,
    type,
    limit,
  } = options
  const ll = urlcat(`${ROOT_URL}/daily_rank`, {
    date,
    order_by,
    sort_by,
    volume_gte,
    volume_lte,
    amount_gte,
    amount_lte,
    type,
    days,
    limit,
  })
  const res = useSWR<DailyRankResourceData[]>([ll, flag], errorEmptyArrayFetcher, {
    revalidateOnFocus: false,
  })

  return {
    data: res.data,
  }
}
/** 即時排行 */
export const useStkfutDailyRankResource = (
  options: DefaultOption & {
    date?: string
    limit?: number
    type?: DailyRankKey
    days?: number
    order_by?: OrderBy
    sort_by?: FrontEndSortKey
  },
  flag: number,
) => {
  const { date, volume_gte, volume_lte, order_by, sort_by, days, type, limit } = options
  const ll = urlcat(`${ROOT_URL}/stkfut_daily_rank`, {
    date,
    order_by,
    sort_by,
    volume_gte,
    volume_lte,
    type,
    days,
    limit,
  })
  const res = useSWR<DailyRankResourceData[]>([ll, flag], errorEmptyArrayFetcher, {
    revalidateOnFocus: false,
    refreshInterval: 15000,
  })

  return {
    data: res.data,
  }
}

/** 熱門股票排行 */
export const usePopularResource = (options: {
  /** 日期 YYYY-MM-DD */
  date: string
  /** 排序條件 volume 、 value */
  sort: string
  /** 個股檔數 */
  limit: number
}) => {
  const { date, sort, limit } = options

  const res = useSWR<PopularResourceData[]>(
    `${ROOT_URL}/popular_stock?date=${date}&sort_by=${sort}&limit=${limit}`,
    errorEmptyArrayFetcher,
    { shouldRetryOnError: false },
  )

  return {
    data: res.data,
  }
}

/** Kd值交叉 */
export const useKdCrossResource = (options: {
  date: string
  cross_type: CrossTypeKey
  fastK?: number
  slowK?: number
  slowD?: number
  days?: number
}) => {
  const { date, cross_type, fastK, slowK, slowD, days } = options
  const url = urlcat(`${ROOT_URL}/kd_cross`, {
    date,
    cross_type,
    fastK,
    slowK,
    slowD,
    days,
  })
  const res = useSWR<KdCrossResourceData>(url, errorEmptyArrayFetcher, {
    shouldRetryOnError: false,
  })

  return {
    data: res.data,
  }
}

export const useAdvanceDeclineResource = (date?: string, flag?: number) => {
  const url = urlcat(`${ROOT_URL}/advance_decline`, {
    date,
  })

  const res = useSWR<AdvanceDeclineResourceData[]>(
    flag ? [url, flag] : url,
    errorEmptyArrayFetcher,
    {
      shouldRetryOnError: false,
    },
  )

  return {
    data: res.data,
  }
}

export const usePercentRangeResource = (
  options: Partial<DefaultOption> & {
    days: number
    status?: string
    date?: string
  },
) => {
  const { volume_gte, volume_lte, amount_gte, amount_lte, days, status, date } = options

  const url = urlcat(`${ROOT_URL}/percent_range`, {
    volume_gte,
    volume_lte,
    amount_gte,
    amount_lte,
    days,
    status,
    date,
  })

  const res = useSWR<PercentRangeResourceData[]>(
    options.days ? url : null,
    errorEmptyArrayFetcher,
    {
      shouldRetryOnError: false,
    },
  )

  return {
    data: res.data,
  }
}

export const useKabujukuResource = (options: {
  date: string
  join: keyof typeof JoinEnum
  indicators: AvailableIndicator[]
}) => {
  const { date, join, indicators } = options
  const url = urlcat(`${ROOT_URL}/kabujuku`, { date, join, indicators })

  const res = useSWR<string[]>(
    options.indicators && options.join ? url : null,
    errorEmptyArrayFetcher,
    {
      shouldRetryOnError: false,
    },
  )

  return {
    data: res.data,
  }
}

export const useWarrantChipResource = (options: {
  date: string
  action: ActionKey
  warrantType: WarrantTypeKey
}) => {
  const { date, action, warrantType } = options
  const url = urlcat(`${ROOT_URL}/warrant_chip`, { date, bs: action, type: warrantType })
  const res = useSWR(url, errorEmptyArrayFetcher, {
    shouldRetryOnError: false,
  })

  return {
    data: res.data,
  }
}

/** 預估量 */
export const useEstimatedTotalVolume = (options: {
  date?: string
  days?: number
  symbol: string
}) => {
  const { date, days, symbol } = options
  const url = urlcat(`${ROOT_URL}/estimated_total_volume`, { date, days, symbol })

  const res = useSWR<{ estimated_total_volume: number }>(
    options.symbol ? url : null,
    errorEmptyArrayFetcher,
    {
      shouldRetryOnError: false,
    },
  )

  return {
    data: res.data,
  }
}

/** 連續收黑/收紅 */
export const useGetContinuousColor = (
  options: Partial<DefaultOption> & {
    date?: string
    days?: number
    order_by?: OrderBy
    /** R:`收紅` g:`收黑` */
    rg: 'r' | 'g'
  },
) => {
  const { volume_gte, volume_lte, amount_gte, amount_lte } = options
  const { days, date, rg, order_by } = options

  const url = urlcat(`${ROOT_URL}/continuous_color`, {
    volume_gte,
    volume_lte,
    amount_gte,
    amount_lte,
    days,
    date,
    rg,
    order_by,
  })

  const res = useSWR<ContinuousColorData[]>(options.days ? url : null, errorEmptyArrayFetcher, {
    shouldRetryOnError: false,
  })

  return {
    data: res.data,
  }
}

/** 台股站上季線月線家數 */
export const useStockAboveMA = (options: {
  date: string
  days?: number
  ma?: number
  market?: string
}) => {
  const { date, days, ma, market } = options
  const url = urlcat(`${ROOT_URL}/above_ma`, { date, days, ma, market })

  const res = useSWR<StockAboveMA[]>(options.date ? url : null, errorEmptyArrayFetcher, {
    shouldRetryOnError: false,
  })

  return {
    data: res.data,
  }
}

/** Winner98 */
export const useMarketLights = (options: { date: string }) => {
  const { date } = options
  const url = urlcat(`${ROOT_URL}/market_lights`, { date })

  const res = useSWR<{ value: number }>(options.date ? url : null, errorEmptyArrayFetcher, {
    shouldRetryOnError: false,
  })

  return {
    data: res.data,
  }
}

/** Sung op日線籌碼分數 */
export const useChipScore = (options: { date: string }) => {
  const { date } = options
  const url = urlcat(`${ROOT_URL}/sung_chip_score`, { date })

  const res = useSWR<{ score: number }>(options.date ? url : null, errorEmptyArrayFetcher, {
    shouldRetryOnError: false,
  })

  return res.data
}

const errorEmptyArrayFetcher = async (url: string) => {
  const res = await fetch(url)

  return !res.ok ? [] : res.json()
}
